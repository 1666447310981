<template>
  <v-row class="w-full">
    <v-spacer />
    <v-card class="w-75 sm:w-full">
      <v-card-title>Login</v-card-title>
      <v-card-text>
        <v-form ref="formLogin" @submit.prevent="login">
          <v-text-field
            v-model="email"
            label="Email"
            type="email"
            :rules="ruleEmail"
          />
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            :rules="rulePassword"
          />
          <v-btn type="submit" color="primary" :loading="isLoading">
            Login
          </v-btn>
          <v-btn
            density="compact"
            size="x-small"
            variant="plain"
            color="secondary"
            to="/auth/reset"
          >
            Forgot Password?
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
    <v-spacer />
  </v-row>
</template>

<script setup>
import LogUserActivity from '~/src/services/logs/log_user_activity'

definePageMeta({
  layout: 'login'
})
const logUserActivity = new LogUserActivity()
const email = ref('')
const password = ref('')
const isLoading = ref(false)
const formLogin = ref()
const runtimeConfig = useRuntimeConfig()

const website = useWebsiteStore()

const ruleEmail = [
  value => !!value || 'required',
  value => (value && /.+@.+\..+/.test(value)) || 'E-mail must be valid'
]
const rulePassword = [value => !!value || 'required']

const login = async () => {
  const { valid } = await formLogin.value?.validate()
  if (valid === true) {
    isLoading.value = true
    logUserActivity.create('attempt login', 'guest', import.meta.url, { email: email.value, password: password.value })
    const data = await useAuthentication(email.value, password.value)

    if (data == null || data === undefined) {
      isLoading.value = false
      website.setFeedback(
        true,
        'Error',
        'Invalid email or password',
        'mdi-alert-circle-outline',
        'error'
      )
      return
    }

    if (data) {
      isLoading.value = false

      website.$patch({ user: data })
      if ((data.role.role === 'executive-marketing' && runtimeConfig.public.mode === 'DEMO') || data.role.role === 'finance' || data.role.role === 'senior-project-engineer') {
        const userMenu = await $fetch('/api/util/menu', {
          method: 'GET',
          params: {
            roleID: data.role.id
          }
        })
        website.$patch({ menu: userMenu.data })

        const defaultMenu = userMenu.data.filter((menu) => {
          return menu.menu.default === 'true'
        })
        if (defaultMenu.length === 0) {
          throw createError({ statusCode: 200, statusMessage: 'No default menu assigned, please contact your system administrator for assistance' })
        } else {
          logUserActivity.create('logged in', data.user_hash_id, import.meta.url, { username: data.username, email: data.email })
          await navigateTo(defaultMenu[0].menu.route)
        }
      } else if (data.role.role === 'coss-manager') {
        await navigateTo('/refinery/coss/')
      } else if (data.role.role === 'coss-sales') {
        await navigateTo('/refinery/coss/sale/coss_list')
      } else if (data.role.role === 'coss-operator') {
        await navigateTo('/refinery/coss/packaging/coss_packaging_daily_report')
      } else {
        await navigateTo('/main')
      }
    } else {
      isLoading.value = false
      website.setFeedback(
        true,
        'Error',
        'Invalid email or password',
        'mdi-alert-circle-outline',
        'error'
      )
    }
  }
}
</script>
